@import "../variables.scss";

.Home {
  text-align: center;
  background-color: $body-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home-logo {
  height: 40vmin;
  pointer-events: none;
  user-select: none;
}

.Home-social-links {
  display: flex;
  flex-direction: row;
}
