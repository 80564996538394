.fade-wrapper {
  opacity: 0;
}
.fade-enter, .appear-enter {
  opacity: 0;
}
.fade-enter-active, .appear-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit, .fade-enter-done {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
