@import './_variables.scss';

* {
  box-sizing: border-box;

  &::selection {
    background-color: $brand-primary;
    color: $text-color-selection;
  }
}

body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  background-color: $body-background;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bitter', serif;
  color: $text-color;
}

h4,
h5,
h6 {
  color: $text-color-light;
  font-weight: normal;
}

a {
  color: $brand-primary;
  text-decoration: none;
  &:visited {
    color: $brand-primary;
  }
  &:hover {
    color: $brand-primary-dark;
  }
}

p {
  margin: 0;
  padding: 0;
}
