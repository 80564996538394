.Hana {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  height: 100vh;
}

.HanaCanvasContainer {
  flex-grow: 1;
  position: relative;

  canvas, img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.HanaControls {
  padding: 20px;
  display: flex;
  min-width: 200px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .HanaColorsContainer {
    display: flex;
    flex-grow: 1;
    margin-top: 5px;
    flex-direction: column;
    justify-items: center;
    width: 100%;
  }

  .HanaColor {
    flex-grow: 1;
    margin: 3px;
    border-radius: 3px
  }
}

.HanaLoader {
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
}

.HanaFadeOut {
  opacity: 0;
  transition: opacity 300ms;
}

.HanaNavigation {
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
}

.HanaContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .HanaContainer {
    flex-direction: column;
    flex-flow: column-reverse;
  }

  .HanaControls {
    flex-direction: row;
    flex-grow: 0;

    .HanaColorsContainer {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 0;
      margin-left: 5px;
    }

    .HanaColor {
      padding: 20px;
    }
  }

  .HanaGitHubLink {
    display: none;
  }
}
