@import '../_variables.scss';

.icon {
    fill: $text-color-light;
    height: 20px;
    width: 20px;
    transition: fill 50ms;

    &:not(:last-child) {
        margin-right: 8px;
        fill: $brand-primary;
    }
}

.iconLink {
    padding: 10px;
    display: flex;

    &:hover .icon {
        fill: $text-color;

        &:not(:last-child) {
            fill: $brand-primary-dark;
        }
    }
}
